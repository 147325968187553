<template>
  <v-sheet style="position: relative;" :class="[{'px-6' : SM}]" >
    <home-section-main class="pt-5 " />
    <home-section-possibilities  />
    <home-section-possibilities-variant />
    <home-section-cases class="mt-12" />
    <home-section-socials-answers />
    <home-section-testimonials class="mt-10" />
    <home-section-paymen-plans />
    <home-section-blog  class="mt-12" />
    <home-section-team  class="mt-12" />
    <home-section-try-now class="mt-12 pb-16" />
  </v-sheet>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

import homeSectionMain from "@/components/pages/homepage_westudy/home/sections/homeSectionMain";
import homeSectionPossibilities from "@/components/pages/homepage_westudy/home/sections/homeSectionPossibilities";
import homeSectionPossibilitiesVariant
  from "@/components/pages/homepage_westudy/home/sections/homeSectionPossibilitiesVariant";
import homeSectionCases from "@/components/pages/homepage_westudy/home/sections/homeSectionCases";
import homeSectionSocialsAnswers from "@/components/pages/homepage_westudy/home/sections/homeSectionSocialsAnswers";
import homeSectionTestimonials from "@/components/pages/homepage_westudy/home/sections/homeSectionTestimonials";
import homeSectionPaymenPlans from "@/components/pages/homepage_westudy/home/sections/homeSectionPaymentPlans";
import homeSectionBlog from "@/components/pages/homepage_westudy/home/sections/homeSectionBlog";
import homeSectionTeam from "@/components/pages/homepage_westudy/home/sections/homeSectionTeam";
import homeSectionTryNow from "@/components/pages/homepage_westudy/home/sections/homeSectionTryNow";

export default {
  name: "HomeMain",
  components : {
    homeSectionMain,
    homeSectionPossibilities,
    homeSectionPossibilitiesVariant,
    homeSectionCases,
    homeSectionSocialsAnswers,
    homeSectionTestimonials,
    homeSectionPaymenPlans,
    homeSectionBlog,
    homeSectionTeam,
    homeSectionTryNow
  },
  data() {
    return {

      displayDialog : false,

      leadLoading : false,
      leadNameError : '',
      leadUniversityError : '',
      leadPhoneError : '',
      leadEmailError : '',
      entityData : {
        name : '',
        position : '',
        phone : '',
        email : '',
        university : '',
        comment : '',
      },
      positionTypes : [
        { text : 'Ректор', value : 'university_rector'},
        { text : 'Проректор', value : 'university_vice_rector'},
        { text : 'Викладач', value : 'university_teacher'},
        { text : 'Директор', value : 'director'},
        { text : 'Завуч', value : 'vice_director'},
        { text : 'Вчитель', value : 'school_teacher'},
        { text : 'Інша', value : 'other'},
      ],


    }
  },
  watch : {
    $route(to) {
      if ( to.hash ) {
        this.scrollTo(to.hash)
      }
    },
  },
  methods : {
    ...mapActions('adminCrmSystem', ['ADD_LEAD']),
    ...mapMutations(['toggleLoginMenu']),

    displayTryNow() {
      this.displayDialog = true
    },

    addLead() {
      let errorMessage = ''
      if ( !this.entityData.university ) { errorMessage= "Вкажіть навчальний заклад" }
      if ( !this.entityData.phone ) { errorMessage= "Вкажіть Ваш контактний телефон" }
      if ( !this.entityData.name ) { errorMessage= "Вкажіть Ваше ім'я" }

      if (errorMessage) { return this.notify(errorMessage) }

      this.leadLoading = true
      this.ADD_LEAD(this.entityData).then((out)=>{
        if ( out.result ) {
          this.notify('Заявку відправлено')
          this.entityData = {
            name : '',
            position : '',
            phone : '',
            email : '',
            university : '',
            comment : '',
          }
        }
        this.leadLoading = false
      }).catch(()=>this.leadLoading = false)
    },
    scrollTo(target) {

      if (!target) { return
        //  target = 'main'
      } else {
        target =  target.substring(1)
      }
      if ( this.$refs[target] === undefined ) { return }
      this.$vuetify.goTo(this.$refs[target][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },
  }
}
</script>

<style lang="scss" scoped>
h4 {
  font-size: 14px !important;
}
tbody {
  tr:hover {
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #6F93AA !important;
    color : whitesmoke;
  }
}
.slide {
  height: 600px;
  background: url('~@/assets/img/dashboard_student.jpg');
  /* background-color: #2c3e50;*/
  background-size: contain;
  background-position: center;
  border-radius: 30px;
  color:white;
  text-align: center;
  padding:70px;
  line-height: 1.2;
}
</style>